<template lang="pug">
  div
    div(style="display:none;position:absolute") {{turn}}
    div(v-show="!showMeet")
      audio#attended(@ended="soundEmmited")
        source(src="@/assets/sounds/colmedica_audio.mp3" type="audio/mpeg")
      audio#advice
        source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
      .row.justify-content-center
        .col-11.header
          div(style="text-align: center")
            img.client_logo(:src="Logo" v-openlog)

      .main_box
        .col-12(style="z-index: 1;")
          h4.mt-4.text-middle.color-colmedica-gray Tu turno asignado es:
          //- h4.text-middle.color-colmedica-gray número:
          h3.text-middle.color-colmedica-light.font-weight-bold {{ assingTurn }}
          h5(v-show="!initMeet").mt-5.text-middle.color-colmedica-gray Próximo turno:
          h5(v-show="!initMeet").text-middle.color-colmedica-light.font-weight-bold {{ actualTurn }}
          h5(v-show="initMeet").mt-5.text-middle.color-colmedica-gray Un momento por favor...

          //- p.color-colmedica En este momento presentamos congestión en nuestro canal, si ya enviaste foto de la orden médica y no vas a acceder inmediatamente al servicio, puedes cancelar la solicitud y te daremos respuesta al correo y te contactaremos si requerimos información adicional..
          p.color-colmedica(v-if="env.VUE_APP_CONGESTION") {{env.VUE_APP_CONGESTION_MESSAGE}}.

          .mt-3.color-colmedica-gray
            b-form-checkbox(
              :class="{'audioNotification isDisabled': !audioNotification, 'audioNotification isEnabled': audioNotification}"
              id="checkbox-1"
              v-model="audioNotification"
              @click="audioNotifyClick"
              name="checkbox-1"
            ) Notificarme con audio cuando sea atendido
          //- p.mt-5.text-center Debido a la situación de orden público en este momento presentamos demoras en la atención de nuestra Oficina Virtual,  gracias por tu comprensión.
      //- .w--100.text-center 
      //-   video(id="video" src="https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4" poster="https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg" controls)
      //-   .col-12.contain.color-colmedica-gray
      //-     p(style="font-size: 1.3rem; text-align: center;") Bienvenido a Autorizaciones médicas Colmédica
      
      
      //- div(class="text-right my-3") {{ version }}
      .main_bottom
        div(v-show="1>2" class="div_center_button" style="bottom: 14vh;")
          b-button(v-b-modal.wait-modal variant="bluecolmedica" class = "modal_button notificationSMSDialog") Notifícame cuando sea mi turno
        div(class="div_center_button")
          u(v-b-modal.cancel-modal class="modal_button cancelTurnDialog color-colmedica-light") Cancelar Solicitud

      b-modal(id="msm-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer)
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") ¡Listo!
          p(class="my-4") Te llegará un mensaje de texto en {{waiting_time}} aproximadamente
          p(class="my-4") Ya puedes cerrar esta ventana
        div(class="div_center_button")           
          b-button(@click="$bvModal.hide('msm-modal')" variant="outline-dark" class="div_center_button") Ok     

      b-modal(id="cancel-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center modal_inside" )
            p(class="my-4 modal_highlight_text") Estás a punto de cancelar la videollamada
            p(class="my-4") Esto cancelará el proceso de atención con el asesor Colmédica
            p(class="my-4") ¿Qué deseas hacer?
          div(class="position-relative")           
            b-button(@click="cancelTurnHandler()" variant="outline-danger" class="btn-block cancelTurnConfirmation") Cancelar la videollamada
            b-button(@click="$bvModal.hide('cancel-modal')" variant="bluecolmedica" class="btn-block") Continuar con el asesor Colmedica

      b-modal(id="new-turn-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") Te hemos asignado el turno
            h3.text-middle.color-colmedica-light.font-weight-bold {{ assingTurn }}
          div(class="position-relative")
            b-button(@click="continueTurn" variant="bluecolmedica" class="btn-block" style="height: 65px; font-size: xx-large;") Continuar

      b-modal(id="confirm-modal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header no-close-on-backdrop)
        div(class="d-block text-center modal_inside" )
          p(class="my-4 modal_highlight_text") Tu asesor esta listo para atenderte
            //- p(class="my-4") Continuar con el asesor Colmedica 
          div(class="position-relative")
            b-button(@click="resolveconfirm" variant="bluecolmedica" class="btn-block" style="height: 72px;") Conectar con asesor

    div(v-if="initMeet")
      Meeting(v-show="showMeet" meetType="customer" :meetId="meetingId" @endMeet="endMeet" :username="username" :isComponent="true" @attendees="attendees")

</template>

<script>
import { mapState, mapActions } from "vuex";
// import Mobile from "../utils/evalmobile";
import _get from "lodash/get";
import kuid from "kuid";
import { setCorrelation } from "../helpers/Meet";
import moment from "moment";
import LogoColmedica from "@/assets/colmedica_logo.png";

import Meeting from "./Meeting";

// import LS from "@/helpers/localStorage";

export default {
  name: "Turno",

  components: {
    Meeting
  },

  // created() {

  //   setTimeout(async () => {

  //   }, 2000);

  // },

  mounted() {
    setTimeout(async () => {
      if (this.actualTurn == "canceled") this.cancelTurnHandler();

      if (window.location.href.indexOf("fv_atril") == -1) {
        this.checkPermissions();
      }

      // show modal turn
      this.$bvModal.show("new-turn-modal");
      // console.log('deberia mostral modal')
      // await new Promise(resolve => this.resolveNewturn = resolve );
      // this.showMeet = false;
      // this.$bvModal.hide('new-turn-modal');

      this.startup();

      let font = _get(this.this_user_data, "fu");
      font = !font || font == "" ? "ad" : font;
      // tracking
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-turno-asignado",
        debmedia_turn_code: this.turnId,
        url_origen: window.location.href,
        origen: font,
        mensajes: ""
      };

      this.sendTracking(trackingData);

      try {
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") {
          hidden = "hidden";
          visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
          hidden = "mozHidden";
          visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
          hidden = "msHidden";
          visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
          hidden = "webkitHidden";
          visibilityChange = "webkitvisibilitychange";
        } else {
          console.error("Page Visibility API not supported.");
        }

        document.addEventListener(
          visibilityChange,
          () => {
            // tracking
            let trackingData = {
              uuid: window.person.id,
              accion: document[hidden]
                ? "customer-visibilidad-oculta"
                : "customer-visibilidad-visible",
              debmedia_turn_code: this.turnId,
              url_origen: window.location.href,
              origen: font,
              mensajes: ""
            };

            this.sendTracking(trackingData);
          },
          false
        );
      } catch (error) {
        // tracking
        let trackingData = {
          uuid: window.person.id,
          accion: "customer-visibilidad-fail",
          debmedia_turn_code: this.turnId,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }
    }, 1000);
  },

  watch: {
    status(val) {
      console.log(val);
      this.checkStatus(val);

      // tracking
      if (val) {
        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-cambio-status: " + val,
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);
      }
    },
    // turnId(val, oldVal) {
    //   console.log('turnId')
    //   console.log(val, oldVal)
    //   // if (oldVal && !val) this.cancelTurnHandler();
    // },
    turnsInFront(val) {
      if (val) this.turnsInFrontCache = val;
    },
    waitingTime(val) {
      if (val) this.waitingTimeCache = val;
    },
    async actualTurn(val) {
      if (!this.audioNotification && val != "canceled") {
        await document.getElementById("advice").play();
      }
      if (val == "canceled") this.cancelTurnHandler();
    },
    audioNotification(val) {
      if (val) {
        document.getElementById("advice").currentTime = 0;
        document.getElementById("advice").play();
        // setTimeout(() => {
        //   document.getElementById("advice").currentTime = 0;
        //   document.getElementById("advice").play();
        // }, 10000);
      }
    },
    turn(val) {
      if (!val) this.cancelTurnHandler();
    }
  },

  beforeDestroy() {
    if (this.lastTimerSound) clearTimeout(this.lastTimerSound);
    if (this.timerSound) clearTimeout(this.timerSound);
  },

  data: () => ({
    LogoColmedica,
    timerSound: null,
    lastTimerSound: null,
    audioNotification: true,
    // isiOS: Mobile.iOS(),
    isiOS: true,
    turnsInFrontCache: null,
    waitingTimeCache: null,
    timeAverageDirect: false, // TODO: Change this value if require to show DebQ api average time result
    errorCoorelationRequest: false,
    errorStartup: false,

    // meet
    initMeet: false,
    showMeet: true,
    showAudioNotification: true,

    connected: false,
    loading: false,

    resolveconfirm: null,
    resolveNewturn: null
  }),

  computed: {
    ...mapState({
      time_received_turn: state => state.time_received_turn,
      turnUUID: state => state.turnUUID,
      version: state => state.version,
      isAtril: state => state.virtualrow.isAtril,
      environment: state => state.environment,
      company: state => state.company,
      company_queue: state => state.company_queue,
      sessionMaster: state => state.sessionMaster,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      tramite_info: state => state.tramite_info,
      env: state => state.env,
      turnCoorelation: state => state.turnCoorelation,

      username() {
        if (!this.turn) return "";
        // return (
        //   `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this.turn
        //     .worker?.actualUser?.uUser?.lastName || ""}`.trim() || "Agente"
        // );
        return (
          `${this.turn.worker?.actualTurn?.getFullName || ""}`.trim() ||
          "Customer"
        );
      }
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    debMediaServer() {
      return this.env.VUE_APP_DEBMEDIA_SERVER;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    iOS() {
      return !!this.isiOS;
    },

    formalityName() {
      if (this.turn && this.company_queue) {
        let formalityData = this.company_queue.filter(
          t => t.id === _get(this.turn, "jsonDetails.queue.id")
        );
        return _get(formalityData, "[0].name");
      }
      return "";
    },

    companyName() {
      return _get(this.company, "name");
    },

    actualTurn() {
      return _get(this.turn, "jsonDetails.actualTurn", "canceled");
    },

    assingTurn() {
      return _get(this.turn, "jsonDetails.turn");
    },

    turnId() {
      return _get(this.turn, "code");
    },
    turnDetails() {
      return this.turnsInFrontCache;
    },
    turnsInFront() {
      return _get(this.turn, "jsonDetails.estimatorResult.turnsInFront");
    },
    waitingTime() {
      return _get(this.turn, "jsonDetails.estimatorResult.waitingTime");
    },
    status() {
      return _get(this.turn, "status");
    },
    userPhone() {
      if (
        _get(this.this_user_data, "phone.length") &&
        this.this_user_data.phone.length > 4
      ) {
        return (
          "*".repeat(this.this_user_data.phone.length - 4) +
          this.this_user_data.phone.slice(-4)
        );
      } else {
        return "";
      }
    },
    waiting_time() {
      if (this.time_received_turn === null) return "60 minutos";
      var now = moment();
      var turn_time = moment.unix(this.time_received_turn);
      var mins_elapsed = now.diff(turn_time, "minute");
      var num_min = Math.ceil(
        this.turnsInFrontCache // Previous: jsonDetails.averageWaitingTime
      );
      var time_to_attend = num_min - mins_elapsed;
      if ((this.timeAverageDirect ? num_min : time_to_attend) > 1) {
        return (
          (this.timeAverageDirect ? num_min : time_to_attend).toString() +
          " minutos"
        );
      } else {
        return "1 minuto";
      }
    },

    // meet

    meetingId() {
      return this.turnCoorelation?.code_title;
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      getTurn: "getTurn",
      setTramite: "setTramite",
      getTurnCoorelation: "getTurnCoorelation",
      putLog: "log/put",
      sendTracking: "virtualrow/handleTracking",
    }),

    audioNotifyClick() {
      // console.log("Notificacion state", this.audioNotification);
    },

    async establishCoorelation() {
      await setCorrelation({
        kuidId: this.turnUUID,
        turnId: kuid(),
        session: this.sessionMaster,
        turn_code: this.turn?.code
      });
    },

    soundEmmited() {
      if (this.timerSound) clearTimeout(this.timerSound);
      this.timerSound = null;
      this.lastTimerSound = setTimeout(() => {
        // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
        // else this.turnToMeetingAction();
        this.turnToMeetingAction();
      }, 1000);
    },

    async checkStatus(newStatus) {
      switch (newStatus) {
        case "ANNOUNCED":
          if (this.audioNotification) {
            if (document.getElementById("attended")) {
              try {
                this.timerSound = setTimeout(() => {
                  try {
                    if (
                      document.getElementById("attended") &&
                      document.getElementById("attended").currentTime > 0
                    )
                      return;
                  } catch (error) {
                    this.$rollbar.warning("Invalid turn audio state", error);
                  }
                  this.soundEmmited();
                }, 2000);
                // await document.getElementById("attended").play();
                document.getElementById("attended").play();
              } catch (error) {
                this.$rollbar.info("Sound of meeting cant play");
                // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
                // else {
                // wait for user
                // this.$bvModal.show('confirm-modal')
                this.$bvModal.hide("new-turn-modal");
                // await new Promise(resolve => this.resolveconfirm = resolve);
                // this.$bvModal.hide('confirm-modal')
                this.turnToMeetingAction();
                // }
              }
            }
          } else {
            // if (this.status !== "ANNOUNCED") this.cancelTurnHandler();
            // else {
            // wait for user
            // this.$bvModal.show('confirm-modal')
            this.$bvModal.hide("new-turn-modal");
            // await new Promise(resolve => this.resolveconfirm = resolve);
            // this.$bvModal.hide('confirm-modal')
            this.turnToMeetingAction();
            // }
          }
          break;
        case "WAITING_TO_BE_CALLED":
          this.showMeet = false;
          break;
        case "FINALIZED":
        case "REVOKED":
        case "CANCELED_BY_USER":
          // case "ABSENT":
          this.cancelTurnHandler();
          break;

        default:
          break;
      }
      return newStatus;
    },

    async turnToMeetingAction() {
      try {
        await this.getTurnCoorelation(this.turnUUID);
        if (!this.audioNotification && this.actualTurn != "canceled")
          document.getElementById("advice").play();
        // LS.setItem("audioNotification", this.audioNotification);
        // this.$router.push(`/meeting/${code_title}`);
        this.initMeet = true;
        this.showMeet = true;
        this.showAudioNotification = false;

        setTimeout(() => {
          let trackingData = {
            uuid: window.person.id,
            accion: "customer-obtencion-id-videollamada",
            debmedia_turn_code: this.turnId,
            url_origen: window.location.href,
            origen: _get(this.this_user_data, "fu") || "ad",
            mensajes: this.meetingId
          };

          this.sendTracking(trackingData);
        }, 1000);
      } catch (error) {
        console.error("In turnToMeetingAction", error);
        this.errorCoorelationRequest = true; // TODO: How show this error? How to recover state?
      }
    },

    async startup() {
      try {
        await this.establishCoorelation();
        this.putLog({
          name: "Turno. Se estableció la coorelación."
        });
        await this.getTurn();
        this.putLog({
          name: "Turno. Se creó el turno."
        });
        if (this.turn) this.checkStatus(this.status);
        if (!this.turnId) this.cancelTurnHandler();
        if (this.turnsInFront) this.turnsInFrontCache = this.turnsInFront;
        if (this.waitingTime) this.waitingTimeCache = this.waitingTime;
      } catch (error) {
        this.errorStartup = true;
        this.putLog({
          name:
            "Turno. Error restrictivo. No fue posible establecer la coorelación o el turno.",
          checked: false,
          message: error
        });
      }
    },
    async cancelTurnHandler() {
      try {
        await this.cancelTurn();
      } catch (error) {
        console.error("Cant cancel turn in component", error);
      }
      this.$router.push({ name: "Canceled" });
    },

    // Meet

    async endMeet() {
      await this.cancelTurn({ local: true });
    },

    checkPermissions() {
      let constraints = { audio: true, video: true };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          console.log("permissions success");
          console.log(stream);
        })
        .catch(err => {
          if (err.name == "NotAllowedError") {
            if (err.message == "Permission denied") {
              return;
            }
            this.checkPermissions();
          }
        });
    },

    attendees(loading) {
      this.loading = loading;
      if (!loading) {
        // Check connection
        this.checkConnection();
      }
    },

    checkConnection() {
      let time;
      setTimeout(() => {
        console.log("Check connection...");
        console.log("loading: " + this.loading);
        if (this.loading) {
          console.log("Reinstanciando...");
          this.initMeet = false;
          this.showMeet = false;
          time = setTimeout(() => {
            console.log("Reinstanció la video llamada");
            this.initMeet = true;
            this.showMeet = true;
            this.checkConnection();
            this.loading = false;
          }, 2000);
        } else {
          this.initMeet = true;
          this.showMeet = true;
          clearTimeout(time);
        }
      }, 10000);
    },

    continueTurn() {
      document.getElementById("advice").play();
      this.$bvModal.hide("new-turn-modal");
    }
  }
};
</script>
<style scoped>
.mediaServer {
  position: absolute;
  width: 100%;
  bottom: 10px;
  font-size: 0.6rem;
}
.task_name {
  font-size: 1.5em;
  padding-bottom: 3vh;
  padding-top: 50px;
  font-family: Helvetica-bold;
}
.main_box {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 1em;
  font-family: Helvetica-light;
}
.info_box {
  position: relative;
  z-index: 0;
  background-color: #00599d;
  color: white;
  border-radius: 7px;
  height: 256px;
}
.client_logo {
  width: 140px;
  height: auto;
}
.personel_logo {
  width: 50%;
  max-width: 275px;
  height: auto;
}
.clock_logo {
  width: 45px;
  height: auto;
}
.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}
.text-vetical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.2em;
  font-weight: 600;
}
.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 20px;
}
.modal_button {
  width: 100%;
  font-size: 1.4em;
  font-family: Helvetica-medium;
}
.modal_highlight_text {
  color: #00599d;
  font-size: 1.1em;
  font-family: Helvetica-bold;
}
.modal_inside {
  color: #979797;
  font-family: Helvetica-medium;
}

@media only screen and (max-width: 528px) {
  .info_box {
    height: 300px;
  }
}

.div_center_button {
  position: relative;
  margin-top: 70px;
}

#video {
  width: 100%;
}
</style>
